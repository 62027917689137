@tailwind base;
@tailwind components;
@tailwind utilities;
@import "flatpickr/dist/themes/airbnb.css";
@import "fonts.css";

@layer base {
  :root {
    --azure-radiance-trace: 210, 100%, 99%;
    /* --azure-radiance-background: #F1F7FF; */
    --azure-radiance-50: 197, 100%, 96%;
    --azure-radiance-100: 200, 100%, 92%;
    --azure-radiance-200: 198, 100%, 85%;
    --azure-radiance-300: 196, 100%, 76%;
    --azure-radiance-400: 198, 100%, 64%;
    --azure-radiance-500: 203, 100%, 56%;
    --azure-radiance-600: 208, 100%, 51%;
    --azure-radiance-700: 212, 100%, 50%;
    --azure-radiance-800: 214, 92%, 40%;
    --azure-radiance-900: 213, 85%, 33%;
    --azure-radiance-950: 214, 74%, 21%;

    --manatee-50: 240, 6%, 97%;
    --manatee-100: 200, 10%, 94%;
    --manatee-200: 216, 9%, 89%;
    --manatee-300: 223, 8%, 82%;
    --manatee-400: 220, 7%, 74%;
    --manatee-500: 220, 7%, 67%;
    --manatee-600: 231, 6%, 57%;
    --manatee-700: 230, 5%, 51%;
    --manatee-800: 233, 4%, 41%;
    --manatee-900: 231, 4%, 34%;
    --manatee-950: 225, 4%, 20%;

    /* --background: 240 5% 96%; */
    /* --background: var(--azure-radiance-background); */
    --background: #F1F7FF;
    /* --foreground: 0 0% 4%; */
    --foreground: var(--azure-radiance-700);

    /* --card: 0 0% 98%; */
    --card: 0 0% 100%;
    /* --card-foreground: 0 0% 4%; */
    --card-foreground: var(--manatee-900);

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 4%;

    --primary: var(--azure-radiance-700);
    --primary-foreground: 0 0% 98%;

    --secondary: var(--manatee-100);
    /* --secondary-foreground: 0 0% 9%; */
    --secondary-foreground: var(--manatee-900);

    /* --muted: 240 6% 90%;
    --muted-foreground: 0 0% 40%; */

    --muted: 0 0% 100%;
    --muted-foreground: var(--manatee-900);

    --accent: 240 6% 84%;
    /* --accent-foreground: 0 0% 8%; */
    --accent-foreground: var(--azure-radiance-700);

    --destructive: 0 84% 64%;
    --destructive-foreground: 0 0% 98%;

    --hover: 240 6% 96%;
    /* --border: 240 6% 96%; */
    --border: var(--manatee-300);
    --input: 240 6% 84%;
    --ring: 0 0% 4%;

    /* --field: 0 0% 100%; */
    --field: var(--azure-radiance-trace);

    --radius: 0.5rem;

    --font-sans: "Inter", sans-serif;
    --font-heading: "Inter", sans-serif;
    --font-inter-sans: "Inter", sans-serif;
  }

  /* Add this to set base font styles */
  .bg-background {
    background-color: #F1F7FF !important;
  }
  html, body {
    min-height: 100vh;
  }
  
  html, body, h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-heading);
  }
  h1, h2, h3, .h1, .h2, .h3 {
    margin-bottom: 0;
    font-weight: 600;
    color: rgb(15 23 42);
  }
  h1, .h1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  h2, .h2, h3, .h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .dark {
    --background: 240 6% 10%;
    --foreground: 0 0% 98%;

    --card: 240 6% 6%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 4%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 240 6% 20%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 6% 20%;
    --muted-foreground: 0 0% 56%;

    --accent: 240 5% 32%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62% 64%;
    --destructive-foreground: 0 0% 98%;

    --hover: 240 6% 10%;
    --border: 240 4% 10%;
    --input: 240 5% 26%;
    --ring: 0 0% 83.1%;

    --field: 0 0% 0%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }

  .recharts-tooltip-wrapper .tabular-nums {
    color: rgb(51 51 51);
    font-weight: 700;
  }

  .recharts-tooltip-wrapper .leading-none {
    color: rgb(51 51 51);;
    font-weight: 700;
  }

  .recharts-tooltip-wrapper .leading-none .text-muted-foreground {
    color: rgba(133, 133, 133, 1);
    font-weight: 600;
  }
}
.bg-background {
  background-color: #F1F7FF !important;
}

body[data-scroll-locked="1"][data-gr-ext-installed] {
  margin-right: 0 !important;
  overflow: auto !important;
}
/* .grid-cols-1 .grid-cols-1 > * {
  width: 350px;
} */
.col-span-2 {
  grid-column: span 2 / span 2;
}
.elementor-widget-container ol {
  list-style: decimal;
}
.elementor-widget-container ul,
.elementor-widget-container ol {
  margin: 0 0 1.4em 20px;
}
.elementor-widget-container p {
  margin-bottom: 17px;
}
.elementor-widget-container ul {
  list-style: disc;
}
.bluecolor {
  color: hsl(var(--foreground)) !important;
}
.customSelect button[data-state="checked"] {
  padding: 2px;
}
.customSelect button[data-state="checked"] span {
  background-color: black;
  height: 10px;
  width: 10px;
  border-radius: 100%;
}
.customSelect button[data-state="checked"] span svg {
  display: none;
}
.formNumber {
  padding: 0 40px 40px;
  border-bottom: 5px solid #e6e6e6;
}
.formNumber.tab-active {
  border-bottom: 5px solid #007bff;
}
.customSelect button svg:last-child,
button.customSelect svg:last-child {
  display: none;
}
.bg-light-gray-theme {
  background-color: #E5E5EA;
}
.bg-light-blue-theme {
  background-color: #5AC8FA;
}
.bg-blue-theme {
  background-color: #007AFF;
}
.text-light-gray-theme {
  color: #E5E5EA;
}
.text-light-blue-theme {
  color: #5AC8FA;
}
.text-blue-theme {
  color: #007AFF;
}
.btn-week-month {
  background-color: #E5F1FF !important;
  color: #0075FD !important;
  border: none !important;
  box-shadow: none !important;
  font-weight: normal !important;
}
.btn-week-month:hover, .btn-week-month:focus, .btn-week-month:active, .btn-week-month-active {
  background-color: #0075FD !important;
  color: #fff !important;
  box-shadow: none !important;
  font-weight: normal !important;
}
.btn-day {
  background-color: #D6D6D6 !important;
  color: #000 !important;
  box-shadow: none !important;
  border: none !important;
  font-weight: normal !important;
}
.btn-day:hover, .btn-day:focus, .btn-day:active, .btn-day-active {
  background-color: #0075FD !important;
  color: #fff !important;
  box-shadow: none !important;
  border: none !important;
  font-weight: normal !important;
}
.calendar-input {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23007AFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='m6 9 6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right center;
  width: 110px;
}
input[type="date" i].calendar-input::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
}


.datepicker-native {
  position: relative;
}
.datepicker-native-input {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  opacity: 0;
}
.datepicker-native-input::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
}
.rounded-0 > .rounded-lg {
  border-radius: 0 !important;
}
.custom-radio-group button[data-state="checked"] span svg {
  display: none;
}
.custom-radio-group button[data-state="checked"] span {
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 100%;
  background: #000;
}
@media (max-width: 768px) {
  .header img {
    max-width: 120px;
    height: auto;
  }
}
@media screen and (max-height: 610px) {
  .modal-theme {
    max-height: 100vh;
    overflow: auto;
  }
}
@layer utilities {
  .image-slider-transition {
    transition: transform 300ms ease-in-out;
  }
}
.landing-page-products .flex.h-16.items-center.justify-center.p-4 {
  margin-top: 1.5rem;
}

/* ===== Scrollbar CSS start ===== */
/* Firefox */
* {
  scrollbar-width: auto;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-thumb {
  background-color: #8d9094;
  border-radius: 10px;
}

@tailwind components;

.home-products-navbar .active-btn {
  background: transparent !important;
  color: #0077ff !important;
  border-radius: 0.5rem !important;
  padding: 0.5rem 0rem !important;
  transition: all 0.2s ease-in-out !important;
  box-shadow: none !important;
  position: relative !important;
}
.home-products-navbar .active-btn::after,
.home-products-navbar button:nth-child(1):hover::after {
  content: "" !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 2px !important;
  background: #0077ff !important;
  display: block !important;
}
.home-products-navbar button:nth-child(1) {
  background: transparent !important;
  padding: 0.5rem 0rem !important;
}
.home-products-navbar button:nth-child(1):hover {
  color: #0077ff !important;
}
.home-products-navbar button:nth-child(1):hover::after {
  content: "" !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 2px !important;
  background: #0077ff !important;
  display: block !important;
}

.left-nav img {
  opacity: .8;
}
.left-nav:not(.engage-icon) a:hover img {
  filter: invert(1);
  opacity: 1;
}
.left-nav.engage-icon a.text-gray-600:hover img {
  filter: invert(1);
  opacity: 1;
}
.left-nav a.shadow-md:hover img {
  filter: invert(0);
  opacity: 1;
}
.custom-checkbox-hide button:disabled {
  opacity: 0 !important;
}
.\[\&\:has\(\[data-state\=checked\]\)\]\:bg-\[\#EFF7FF\]:has([data-state=checked]) .rounded-md.border.p-3 {
  border: 1px solid #0077ff;
  background: #fff;
}
.\[\&\:has\(\[data-state\=checked\]\)\]\:bg-\[\#EFF7FF\]:has([data-state=checked]) .rounded-md.border.p-3 svg {
  stroke: #0077ff;
}

span.flatpickr-day.today:not(.selected):hover, span.flatpickr-day.prevMonthDay.today:not(.selected):hover, span.flatpickr-day.nextMonthDay.today:not(.selected):hover {
  border: 1px solid #0077ff !important;
}

span.flatpickr-day.today:not(.selected), span.flatpickr-day.prevMonthDay.today:not(.selected), span.flatpickr-day.nextMonthDay.today:not(.selected) {
  border-bottom-color: #0077ff !important;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: #0077ff !important;
  background: #0077ff !important;
  color: #fff !important;
}
span.flatpickr-day.today.flatpickr-disabled {
  color: #333 !important;
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #0077ff !important;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #0077ff !important;
}
input {
  font-family: sans-serif;
}
@media (min-width: 992px) {
  aside#drawer-navigation.lg\:w-16 div#sidebar-usage-summary {
    display: none;
  }
  aside#drawer-navigation.lg\:w-16 .ml-3 {
    margin-left: 0;
  }
  aside#drawer-navigation.lg\:w-16 img.mr-3 {
    height: 24px;
    width: 24px;
  }  
  aside#drawer-navigation.lg\:w-16 #sidebar-usage-summary-dev {
    display: flex;
  }
}
@media (max-width: 991px) {
  aside#drawer-navigation.lg\:w-16 #sidebar-usage-summary {
    display: none;
  }
}
#sidebar-usage-summary-dev {
  display: none;
}
.engage-icon a {
  justify-content: start !important;
}
.font-Barlow {
  font-family: "Barlow", serif;
}
.recharts-responsive-container:active,
.recharts-wrapper:active,
.recharts-responsive-container:target,
.recharts-wrapper:target,
.recharts-responsive-container:hover,
.recharts-wrapper:hover,
.recharts-responsive-container:focus,
.recharts-wrapper:focus,
.recharts-responsive-container:focus-visible,
.recharts-wrapper:focus-visible,
.recharts-responsive-container:focus-within,
.recharts-wrapper:focus-within,
.recharts-wrapper svg:focus {
  outline: none !important;
}
